import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  isLive: boolean;

  constructor(private dataService: DataService, private patientService: PatientService) {
    this.isLive = false;
  }

  ngOnInit() {
    
    this.dataService.status$.subscribe(status => {

      if (status === 'started') {
        return this.isLive = true;
      }

      this.isLive = false;
    });
  }

}
