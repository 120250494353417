import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ChartOptions, Options } from 'highcharts';
import { ChartCollection } from '../models/chart-collection';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor() {

  }

  public icuCharts: ChartCollection = {

    urea: {
      ektv: {
        chart: new Chart(this.getChartOptions('ektv', 'eKtV', 'eKt/V', 'urea')),
        lastValue: null
      },
      tru: {
        chart: new Chart(this.getChartOptions('tru', 'TRU', 'mmol', 'urea')),
        lastValue: null
      },
      urea: {
        chart: new Chart(this.getChartOptions('urea', 'Urea', 'mmol/L', 'urea')),
        lastValue: null
      },
      rr: {
        chart: new Chart(this.getChartOptions('rr', 'RR%', '%', 'urea')),
        lastValue: null
      },
    },

    b2m: {
      rr: {
        chart: new Chart(this.getChartOptions('rr', 'RR%', '%', 'b2m')),
        lastValue: null
      },
      tru: {
        chart: new Chart(this.getChartOptions('tru', 'TRU', 'mg', 'b2m')),
        lastValue: null
      },
      b2m: {
        chart: new Chart(this.getChartOptions('b2m', 'B2M', 'mg/L', 'b2m')),
        lastValue: null
      }
    },

    is: {
      rr: {
        chart: new Chart(this.getChartOptions('rr', 'RR%', '%', 'is')),
        lastValue: null
      },
      tru: {
        chart: new Chart(this.getChartOptions('tru', 'TRU', 'micromol', 'is')),
        lastValue: null
      },
      is: {
        chart: new Chart(this.getChartOptions('is', 'IS', 'micromol/L', 'is')),
        lastValue: null
      }
    }

  };

  getChartOptions(id: string, name: string, unit: string, molecule?: string): object {

    let molColour = '#7cb5ec';

    switch (molecule) {
      case 'urea':
        molColour = '#ff5252';
      break;
      case 'b2m':
        molColour = '#ff6100';
      break;
      case 'is':
        molColour = '#00d58d';
      break;
    }

    let colours: any[] = [
      [0, molColour],
      [1, 'rgba(255,255,255,0)']
    ];

    const options: any = {

      chart: {
        type: 'area',
        backgroundColor: 'transparent'
      },
      legend: {
        enabled: false
      },
      title: {
        text: '',
        align: 'center',
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Time'
        }
      },
      yAxis: {
        title: {
          text: unit
        }
      },
      series: [
        {
          name: name,
          id: id,
          data: []
        }
      ],
      time: {
        useUTC: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: colours
          },
          marker: {
            enabled: false
          }
        },
        series: {
          color: molColour
        }
      },
      tooltip: {
        xDateFormat: '%d.%m.%Y %H:%M:%S'
      },
      boost: {
        useGPUTranslations: true
      }
      
    };

    return options;
    
  }


}
