import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Patient } from 'src/app/models/patient';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent implements OnInit {

  patients: Patient[] = [

    {
      firstName: 'Jane',
      lastName: 'Doe',
      id: 45503124212,
      age: 40,
      image: '',
      online: true,
      sex: 'female',
      weight: 45.3
    },

    {
      firstName: 'John',
      lastName: 'Doe',
      id: 36301233411,
      age: 56,
      image: '',
      online: false,
      sex: 'male',
      weight: 120
    }
  ];

  constructor(private dataService: DataService, private patientService: PatientService) { }

  ngOnInit() {
  }

  start() {
    this.dataService.getData();
  }

  selectPatient(patient: Patient) {
    this.patientService.setPatient(patient);
    this.start();
  }

}
