import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit {

  goal: number;
  cleanValue: number;

  constructor(public dataService: DataService) {
    this.cleanValue = 0;
    this.goal = 100 - 70; // Where 70 is the actual goal value
  }

  ngOnInit() {

    this.dataService.clean$.subscribe(value => {
      this.cleanValue = value;
    });
  }

  goalValue(): string {

    return this.goal + 7.5 + '%';

  }

  currentValue(): string {
    return 100 - 80 + '%';
  }

}
