import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IcuComponent } from './icu.component';
import { B2mComponent } from './b2m/b2m.component';
import { IsComponent } from './is/is.component';
import { UreaComponent } from './urea/urea.component';


const icuModuleRoutes: Routes = [
    {
        path: 'icu',
        component: IcuComponent,
        children: [
            {
                path:'urea',
                component: UreaComponent
            },
            {
                path:'is',
                component: IsComponent
            },
            {
                path:'b2m',
                component: B2mComponent
            },
            {
                path: '',
                redirectTo: '/icu/urea',
                pathMatch: 'full'
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(icuModuleRoutes)
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class IcuRoutingModule { }