import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, interval } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ChartService } from 'src/app/services/chart.service';
import { takeUntil } from 'rxjs/operators';
import { ChartObject } from 'highcharts';

@Component({
  selector: 'app-b2m',
  templateUrl: './b2m.component.html',
  styleUrls: ['./b2m.component.scss']
})
export class B2mDetailComponent implements OnInit, OnDestroy {
  
  private destroy$: Subject<void>;
  private sub: Subscription;

  private b2mSub: Subscription;

  private b2mChart: ChartObject;

  constructor(public chartService: ChartService, public dataService: DataService) {
    this.destroy$ = new Subject();
  }

  ngOnInit() {

    this.sub = this.dataService.status$.subscribe(value => {

      if (value === 'not-started') return;

      this.b2mSub = this.chartService.icuCharts.b2m.rr.chart.ref$.subscribe(chart => {
        this.b2mChart = chart;
        this.b2mChart.redraw();
      });

      if (value === 'started') {
        this.redrawInterval();
      }
      if (value === 'stopped') {
        this.destroy$.next();
      }

    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.sub.unsubscribe();
    
    if (this.b2mSub) this.b2mSub.unsubscribe();
  }

  redrawInterval() {
    interval(1000).pipe(takeUntil(this.destroy$)).subscribe(() => this.redrawCharts());
  }

  redrawCharts() {
    if (this.b2mChart) this.b2mChart.redraw();
  }

}
