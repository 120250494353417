import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';
import { Subject, interval, Subscription } from 'rxjs';
import { takeUntil, expand } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ChartObject } from 'highcharts/highstock';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-urea',
  templateUrl: './urea.component.html',
  styleUrls: ['./urea.component.scss']
})
export class UreaComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void>;
  private sub: Subscription;

  private urrSub: Subscription;
  private truSub: Subscription;
  private ureaSub: Subscription;

  private urrChart: ChartObject;
  private truChart: ChartObject;
  private ureaChart: ChartObject;

  public expandedChart: string;

  constructor(public chartService: ChartService, public dataService: DataService, private router: Router) {
    
    this.destroy$ = new Subject();
    this.expandedChart = null;

    this.sub = this.dataService.status$.subscribe(value => {

      if (value === 'not-started') return;

      // this.router.events.subscribe(event => {
      //     if (event instanceof NavigationStart) {

      //       this.urrSub = this.chartService.icuCharts.urea.rr.chart.ref$.subscribe(chart => {
      //         chart.setSize(undefined, window.innerHeight * 0.25);
      //         this.urrSub.unsubscribe();
      //       });
      //       this.truSub = this.chartService.icuCharts.urea.tru.chart.ref$.subscribe(chart => {
      //         chart.setSize(undefined, window.innerHeight * 0.25);
      //         this.truSub.unsubscribe();
      //       });
      //       this.ureaSub = this.chartService.icuCharts.urea.urea.chart.ref$.subscribe(chart => {
      //         chart.setSize(undefined, window.innerHeight * 0.25);
      //         this.ureaSub.unsubscribe();
      //       });

      //     } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {

      //     }
      // }, () => {

      // });

      this.urrSub = this.chartService.icuCharts.urea.rr.chart.ref$.subscribe(chart => {
        this.urrChart = chart;
        this.urrChart.redraw();
      });
      this.truSub = this.chartService.icuCharts.urea.tru.chart.ref$.subscribe(chart => {
        this.truChart = chart;
        this.truChart.redraw();
      });
      this.ureaSub = this.chartService.icuCharts.urea.urea.chart.ref$.subscribe(chart => {
        this.ureaChart = chart;
        this.ureaChart.redraw();
      });

      if (value === 'started') {
        this.redrawInterval();
      }
      if (value === 'stopped') {
        this.destroy$.next();
      }

    });

  }

  ngOnInit() {

  }

  ngOnDestroy(): void {

    this.destroy$.next();
    this.sub.unsubscribe();

    this.urrSub = this.chartService.icuCharts.urea.rr.chart.ref$.subscribe(chart => {
      chart.setSize(undefined, window.innerHeight * 0.25);
      this.urrSub.unsubscribe();
    });
    this.truSub = this.chartService.icuCharts.urea.tru.chart.ref$.subscribe(chart => {
      chart.setSize(undefined, window.innerHeight * 0.25);
      this.truSub.unsubscribe();
    });
    this.ureaSub = this.chartService.icuCharts.urea.urea.chart.ref$.subscribe(chart => {
      chart.setSize(undefined, window.innerHeight * 0.25);
      this.ureaSub.unsubscribe();
    });

  }

  redrawInterval() {
    interval(1000).pipe(takeUntil(this.destroy$)).subscribe(() => this.redrawCharts());
  }

  redrawCharts() {
    if (this.urrChart && this.ureaChart && this.truChart) {
      this.urrChart.redraw();
      this.truChart.redraw();
      this.ureaChart.redraw();
    }
  }

  expand(chartName: string) {

    let selectedChart: ChartObject = null;

    switch (chartName) {
      case 'rr':
        selectedChart = this.urrChart;
        break;
      case 'tru':
        selectedChart = this.truChart;
        break;
      case 'urea':
        selectedChart = this.ureaChart;
        break;
    }

    if (selectedChart === null) return;

    if (this.expandedChart === chartName) {
      this.expandedChart = null;

      selectedChart.setSize(undefined, window.innerHeight * 0.25);
      return;
    }

    this.expandedChart = chartName;
    selectedChart.setSize(undefined, window.innerHeight * 0.6);

  }

}
