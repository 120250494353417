import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'progress-ring',
  templateUrl: './progress-ring.component.html',
  styleUrls: ['./progress-ring.component.scss']
})
export class ProgressRingComponent implements OnInit, OnDestroy {

  @Input() goalValue: number;
  @Input() currentValue: number;
  @Input() label: string;
  @Input() colour: string;
  @Input() displayGoal: boolean;

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public strokeWidth: number = 5;
  public width: number = 120;
  public radius: number = (this.width / 2) - (this.strokeWidth * 2);
  public circumference = this.radius * 2 * Math.PI;

  private destroy$ = new Subject();

  constructor(private dataService: DataService) { }

  ngOnInit() {

  }

}
