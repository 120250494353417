import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PatientsComponent } from './views/patients/patients.component';
import { DetailComponent } from './views/detail/detail.component';
import { PatientComponent } from './views/patient/patient.component';
import { LongTermComponent } from './views/longterm/longterm.component';

const routes = [
  {
    path: 'patients',
    component: PatientsComponent
  },
  {
    path: 'patient',
    component: PatientComponent
  },
  { 
    path: '', 
    redirectTo: '/patients', 
    pathMatch: 'full' 
  },
  { 
    path: 'longterm', 
    component: LongTermComponent
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
