import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';
import { Subject, Subscription, interval } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { takeUntil } from 'rxjs/operators';
import { ChartObject } from 'highcharts';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-b2m',
  templateUrl: './b2m.component.html',
  styleUrls: ['./b2m.component.scss']
})
export class B2mComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void>;
  private sub: Subscription;

  private rrSub: Subscription;
  private truSub: Subscription;
  private b2mSub: Subscription;

  private rrChart: ChartObject;
  private truChart: ChartObject;
  private b2mChart: ChartObject;
  expandedChart: string;

  constructor(public chartService: ChartService, public dataService: DataService, private router: Router) {
    this.destroy$ = new Subject();
  }

  ngOnInit() {

    this.sub = this.dataService.status$.subscribe(value => {

      if (value === 'not-started') return;

      // this.router.events.subscribe(event => {
      //     if (event instanceof NavigationStart) {

      //       this.rrSub = this.chartService.icuCharts.b2m.rr.chart.ref$.subscribe(chart => {
      //         chart.setSize(undefined, window.innerHeight * 0.25);
      //         this.rrSub.unsubscribe();
      //       });
      //       this.truSub = this.chartService.icuCharts.b2m.tru.chart.ref$.subscribe(chart => {
      //         chart.setSize(undefined, window.innerHeight * 0.25);
      //         this.truSub.unsubscribe();
      //       });
      //       this.b2mSub = this.chartService.icuCharts.b2m.b2m.chart.ref$.subscribe(chart => {
      //         chart.setSize(undefined, window.innerHeight * 0.25);
      //         this.b2mSub.unsubscribe();
      //       });

      //     } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {

      //     }
      // }, () => {

      // });

      this.rrSub = this.chartService.icuCharts.b2m.rr.chart.ref$.subscribe(chart => {
        this.rrChart = chart;
        this.rrChart.redraw();
      });
      this.truSub = this.chartService.icuCharts.b2m.tru.chart.ref$.subscribe(chart => {
        this.truChart = chart;
        this.truChart.redraw();
      });
      this.b2mSub = this.chartService.icuCharts.b2m.b2m.chart.ref$.subscribe(chart => {
        this.b2mChart = chart;
        this.b2mChart.redraw();
      });
  
      // this.redrawCharts();

      if (value === 'started') {
        this.redrawInterval();
      }
      if (value === 'stopped') {
        this.destroy$.next();
      }

    });
  }

  ngOnDestroy(): void {

    this.rrSub = this.chartService.icuCharts.b2m.rr.chart.ref$.subscribe(chart => {
      chart.setSize(undefined, window.innerHeight * 0.25);
      this.rrSub.unsubscribe();
    });
    this.truSub = this.chartService.icuCharts.b2m.tru.chart.ref$.subscribe(chart => {
      chart.setSize(undefined, window.innerHeight * 0.25);
      this.truSub.unsubscribe();
    });
    this.b2mSub = this.chartService.icuCharts.b2m.b2m.chart.ref$.subscribe(chart => {
      chart.setSize(undefined, window.innerHeight * 0.25);
      this.b2mSub.unsubscribe();
    });

    this.destroy$.next();
    this.sub.unsubscribe();
  }

  redrawInterval() {
    interval(1000).pipe(takeUntil(this.destroy$)).subscribe(() => this.redrawCharts());
  }

  redrawCharts() {
    if (this.rrChart && this.b2mSub && this.truChart) {
      this.rrChart.redraw();
      this.truChart.redraw();
      this.b2mChart.redraw();
    }
  }

  expand(chart: string) {

    const parent = event.srcElement.parentElement;
    let selectedChart = null;

    switch (chart) {
      case 'rr':
        selectedChart = this.rrChart;
        break;
      case 'tru':
        selectedChart = this.truChart;
        break;
      case 'b2m':
        selectedChart = this.b2mChart;
        break;
    }


    if (this.expandedChart === chart) {
      this.expandedChart = null;
      selectedChart.setSize(undefined, window.innerHeight * 0.25);
      return;
    }

    this.expandedChart = chart;
    selectedChart.setSize(undefined, window.innerHeight * 0.6);

  }

}
