import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, interval } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { takeUntil } from 'rxjs/operators';
import { ChartService } from 'src/app/services/chart.service';
import { ChartObject } from 'highcharts';

@Component({
  selector: 'app-urea',
  templateUrl: './urea.component.html',
  styleUrls: ['./urea.component.scss']
})
export class UreaDetailComponent implements OnInit, OnDestroy {
  
  private destroy$: Subject<void>;
  private sub: Subscription;

  private ureaSub: Subscription;

  private ureaChart: ChartObject;

  constructor(public chartService: ChartService, public dataService: DataService) {
    this.destroy$ = new Subject();
  }

  ngOnInit() {

    this.sub = this.dataService.status$.subscribe(value => {

      if (value === 'not-started') return;

      this.ureaSub = this.chartService.icuCharts.urea.rr.chart.ref$.subscribe(chart => {
        this.ureaChart = chart;
        this.ureaChart.redraw();
      });

      if (value === 'started') {
        this.redrawInterval();
      }
      if (value === 'stopped') {
        this.destroy$.next();
      }

    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.sub.unsubscribe();
    
    if (this.ureaSub) this.ureaSub.unsubscribe();
  }

  redrawInterval() {
    interval(1000).pipe(takeUntil(this.destroy$)).subscribe(() => this.redrawCharts());
  }

  redrawCharts() {
    if (this.ureaChart) this.ureaChart.redraw();
  }

}
