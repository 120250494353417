import { Injectable } from '@angular/core';
import { Patient } from '../models/patient';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  currentPatient: Patient;

  constructor() {

    // Placeholder patient
    this.currentPatient = {
      firstName: 'John',
      lastName: 'Doe',
      id: 36301233411,
      age: 56,
      image: '',
      online: true,
      sex: 'male',
      weight: 120
    };
  }

  setPatient(patient: Patient) {
    this.currentPatient = patient;
  }
}
