import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';
import { Subject, Subscription, interval } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { takeUntil } from 'rxjs/operators';
import { ChartObject } from 'highcharts';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-is',
  templateUrl: './is.component.html',
  styleUrls: ['./is.component.scss']
})
export class IsComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void>;
  private sub: Subscription;

  private rrSub: Subscription;
  private truSub: Subscription;
  private isSub: Subscription;

  private rrChart: ChartObject;
  private truChart: ChartObject;
  private isChart: ChartObject;
  public expandedChart: string;

  constructor(public chartService: ChartService, public dataService: DataService, private router: Router) {
    this.destroy$ = new Subject();
    this.expandedChart = null;
  }

  ngOnInit() {

    this.sub = this.dataService.status$.subscribe(value => {

      if (value === 'not-started') return;

      // this.router.events.subscribe(event => {
      //     if (event instanceof NavigationStart) {

      //       this.rrSub = this.chartService.icuCharts.is.rr.chart.ref$.subscribe(chart => {
      //         chart.setSize(undefined, window.innerHeight * 0.25);
      //         this.rrSub.unsubscribe();
      //       });
      //       this.truSub = this.chartService.icuCharts.is.tru.chart.ref$.subscribe(chart => {
      //         chart.setSize(undefined, window.innerHeight * 0.25);
      //         this.truSub.unsubscribe();
      //       });
      //       this.isSub = this.chartService.icuCharts.is.is.chart.ref$.subscribe(chart => {
      //         chart.setSize(undefined, window.innerHeight * 0.25);
      //         this.isSub.unsubscribe();
      //       });

      //     } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {

      //     }
      // }, () => {

      // });

      this.rrSub = this.chartService.icuCharts.is.rr.chart.ref$.subscribe(chart => {
        this.rrChart = chart;
        this.rrChart.redraw();
      });
      this.truSub = this.chartService.icuCharts.is.tru.chart.ref$.subscribe(chart => {
        this.truChart = chart;
        this.truChart.redraw();
      });
      this.isSub = this.chartService.icuCharts.is.is.chart.ref$.subscribe(chart => {
        this.isChart = chart;
        this.isChart.redraw();
      });
  
      // this.redrawCharts();

      if (value === 'started') {
        this.redrawInterval();
      }
      if (value === 'stopped') {
        this.destroy$.next();
      }

    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.sub.unsubscribe();
    
    this.rrSub = this.chartService.icuCharts.is.rr.chart.ref$.subscribe(chart => {
      chart.setSize(undefined, window.innerHeight * 0.25);
      this.rrSub.unsubscribe();
    });
    this.truSub = this.chartService.icuCharts.is.tru.chart.ref$.subscribe(chart => {
      chart.setSize(undefined, window.innerHeight * 0.25);
      this.truSub.unsubscribe();
    });
    this.isSub = this.chartService.icuCharts.is.is.chart.ref$.subscribe(chart => {
      chart.setSize(undefined, window.innerHeight * 0.25);
      this.isSub.unsubscribe();
    });
    
  }

  redrawInterval() {
    interval(1000).pipe(takeUntil(this.destroy$)).subscribe(() => this.redrawCharts());
  }

  redrawCharts() {
    if (this.rrChart && this.isChart && this.truChart) {
      this.rrChart.redraw();
      this.truChart.redraw();
      this.isChart.redraw();
    }
  }

  expand(chartName: string) {

    let selectedChart: ChartObject = null;

    switch (chartName) {
      case 'rr':
        selectedChart = this.rrChart;
        break;
      case 'tru':
        selectedChart = this.truChart;
        break;
      case 'is':
        selectedChart = this.isChart;
        break;
    }

    if (selectedChart === null) return;

    if (this.expandedChart === chartName) {
      this.expandedChart = null;

      selectedChart.setSize(undefined, window.innerHeight * 0.25);
      return;
    }

    this.expandedChart = chartName;
    selectedChart.setSize(undefined, window.innerHeight * 0.6);

  }

}
