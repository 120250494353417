import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {

  constructor(public dataService: DataService, public patientService: PatientService) {
    
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    
  }

}
