import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-icu',
  templateUrl: './icu.component.html',
  styleUrls: ['./icu.component.scss']
})
export class IcuComponent implements OnInit, OnDestroy {

  constructor(public dataService: DataService, public patientService: PatientService) { 

  }

  start() {
    this.dataService.getData();
  }

  stop() {
    this.dataService.stopData();
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {

  }

}
