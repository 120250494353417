import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, timer, Subject, interval, BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil, catchError } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { ChartService } from './chart.service';
import { Point } from 'angular-highcharts/lib/chart';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  public clean$: BehaviorSubject<number>;
  public status$: BehaviorSubject<string>;

  public liquidGoalValue: number;
  public liquidCurrent: number;
  public timeRemaining: number;
  public totalTime: any;
  public ureaRr: number;

  private isStarted: boolean;
  private values: any[];
  private position: number;
  private destroy$: Subject<void>;

  constructor(private http: HttpClient, private chartService: ChartService) {
    this.clean$ = new BehaviorSubject(0);
    this.status$ = new BehaviorSubject('not-started');
    this.destroy$ = new Subject();
    this.isStarted = false;
    this.position = 0;
    this.values = [];
    this.totalTime = 0;
    this.timeRemaining = 0;
    this.liquidGoalValue = 0;
    this.liquidCurrent = 0;

    this.status$.subscribe(value => {

      if (value === 'started') {
        this.isStarted = true;
      }

    });
  }

  getMockData() {
    return this.http.get('assets/data/dialysis.txt', { responseType: 'text' }).pipe(catchError((error: any) => observableThrowError(error || 'Server error')));
  }

  getData() {

    if (this.isStarted === true) return;

    this.status$.next('started');

    this.getMockData().subscribe(data => {

      if (!data) return console.log('no mock data available');

      let list = data.toString().split('\n');

      for (var i = 0; i < list.length; i++) {
        let item = list[i].split(/\s+/).map(item => {
          return parseFloat(item).toFixed(2);
        });
        this.values.push(item);
      }

      this.totalTime = Math.round(this.values[this.values.length - 2][1] / 60);
      // this.liquidGoalValue = this.values[this.values.length - 2][15];
      this.liquidGoalValue = 3;

      this.isStarted = true;

      interval(150).pipe(takeUntil(this.destroy$)).subscribe(() => {

        if (this.values.length <= this.position) {
          this.destroy$.next();
          this.status$.next('stopped');
          this.timeRemaining = 0;
          return;
        }

        let item = this.values[this.position];
        this.position += 50;
        this.timeRemaining = this.totalTime - (Math.round(item[1] / 60));

        this.clean$.next(Math.floor(item[7]));
        this.liquidCurrent = item[15];

        // Format datapoints
        const ureaEktv: Point = [(new Date()).getTime(), parseFloat(item[6])];
        const ureaTru: Point = [(new Date()).getTime(), parseFloat(item[11])];
        const ureaUrea: Point = [(new Date()).getTime(), parseFloat(item[2])];
        const ureaRr: Point = [(new Date()).getTime(), parseFloat(item[7])];

        const isRr: Point = [(new Date()).getTime(), parseFloat(item[10])];
        const isTru: Point = [(new Date()).getTime(), parseFloat(item[14])];
        const is: Point = [(new Date()).getTime(), parseFloat(item[5])];

        const b2mRr: Point = [(new Date()).getTime(), parseFloat(item[9])];
        const b2mTru: Point = [(new Date()).getTime(), parseFloat(item[13])];
        const b2m: Point = [(new Date()).getTime(), parseFloat(item[4])];
  
        // Set chart values
        this.chartService.icuCharts.urea.ektv.chart.addPoint(ureaEktv, 0, false);
        this.chartService.icuCharts.urea.tru.chart.addPoint(ureaTru, 0, false);
        this.chartService.icuCharts.urea.urea.chart.addPoint(ureaUrea, 0, false);
        this.chartService.icuCharts.urea.rr.chart.addPoint(ureaRr, 0, false);

        this.chartService.icuCharts.b2m.rr.chart.addPoint(b2mRr, 0, false);
        this.chartService.icuCharts.b2m.tru.chart.addPoint(b2mTru, 0, false);
        this.chartService.icuCharts.b2m.b2m.chart.addPoint(b2m, 0, false);

        this.chartService.icuCharts.is.rr.chart.addPoint(isRr, 0, false);
        this.chartService.icuCharts.is.tru.chart.addPoint(isTru, 0, false);
        this.chartService.icuCharts.is.is.chart.addPoint(is, 0, false);

        // Set last values
        this.chartService.icuCharts.urea.ektv.lastValue = ureaEktv[1];
        this.chartService.icuCharts.urea.tru.lastValue = ureaTru[1];
        this.chartService.icuCharts.urea.urea.lastValue = ureaUrea[1];
        this.chartService.icuCharts.urea.rr.lastValue = ureaRr[1];

        this.chartService.icuCharts.b2m.rr.lastValue = b2mRr[1];
        this.chartService.icuCharts.b2m.tru.lastValue = b2mTru[1];
        this.chartService.icuCharts.b2m.b2m.lastValue = b2m[1];

        this.chartService.icuCharts.is.rr.lastValue = isRr[1];
        this.chartService.icuCharts.is.tru.lastValue = isTru[1];
        this.chartService.icuCharts.is.is.lastValue = is[1];

      }, error => console.warn(error));

    });

  }


  stopData() {
    this.destroy$.next();
  }

  getLongterm(min, max): any[] {

    let set = [];
    const dates = [
      new Date(2019, 2, 1),
      new Date(2019, 2, 4),
      new Date(2019, 2, 8), 
      new Date(2019, 2, 12), 
      new Date(2019, 2, 14), 
      new Date(2019, 2, 16), 
      new Date(2019, 2, 18), 
      new Date(2019, 2, 22), 
      new Date(2019, 2, 24), 
      new Date(2019, 2, 26), 
      new Date(2019, 2, 28), 
      new Date(2019, 2, 30)
    ];

    for (var i = 0; i < 12; i++) {
      const item = [
        dates[i].getTime(),
        this.getRandomInt(min, max)
      ];
      set.push(item);
    }

    return set;
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

}
