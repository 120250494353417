import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { PatientsComponent } from './views/patients/patients.component';
import { IcuComponent } from './views/icu/icu.component';
import { PatientComponent } from './views/patient/patient.component';
import { DetailComponent } from './views/detail/detail.component';
import { LongTermComponent } from './views/longterm/longterm.component';

import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { HttpClientModule } from '@angular/common/http';

import * as boost from 'highcharts/modules/boost.src';
import { UreaComponent } from './views/icu/urea/urea.component';
import { B2mComponent } from './views/icu/b2m/b2m.component';
import { IsComponent } from './views/icu/is/is.component';
import { IcuRoutingModule } from './views/icu/icu-routing.module';
import { DetailRoutingModule } from './views/detail/detail-routing.module';
import { B2mDetailComponent } from './views/detail/b2m/b2m.component';
import { IsDetailComponent } from './views/detail/is/is.component';
import { UreaDetailComponent } from './views/detail/urea/urea.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ProgressRingComponent } from './components/progress-ring/progress-ring.component';

@NgModule({
  declarations: [
    AppComponent,
    PatientsComponent,
    IcuComponent,
    PatientComponent,
    DetailComponent,
    LongTermComponent,
    UreaComponent,
    B2mComponent,
    IsComponent,
    UreaDetailComponent,
    B2mDetailComponent,
    IsDetailComponent,
    NavigationComponent,
    ProgressRingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IcuRoutingModule,
    DetailRoutingModule,
    ChartModule,
    HttpClientModule
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ boost ] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
