import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DetailComponent } from './detail.component';
import { UreaDetailComponent } from './urea/urea.component';
import { IsDetailComponent } from './is/is.component';
import { B2mDetailComponent } from './b2m/b2m.component';

const detailModuleRoutes: Routes = [
  {
      path: 'detail',
      component: DetailComponent,
      children: [
        {
            path:'urea',
            component: UreaDetailComponent
        },
        {
            path:'is',
            component: IsDetailComponent
        },
        {
            path:'b2m',
            component: B2mDetailComponent
        },
        {
            path: '',
            redirectTo: '/detail/urea',
            pathMatch: 'full'
        }
      ]
  }
];

@NgModule({
  imports: [
      RouterModule.forChild(detailModuleRoutes)
  ],
  declarations: [],
  exports: [
      RouterModule
  ]
})

export class DetailRoutingModule { }
