import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Chart } from 'angular-highcharts';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-longterm',
  templateUrl: './longterm.component.html',
  styleUrls: ['./longterm.component.scss']
})
export class LongTermComponent implements OnInit {

  chart: Chart;


  constructor(public dataService: DataService, public patientService: PatientService) {

    this.chart = new Chart({

      chart: {
        type: 'area',
        backgroundColor: 'transparent'
      },
      legend: {
        enabled: true,
        align: 'left',
        padding: 40
      },
      title: {
        text: '',
        align: 'center',
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Time'
        }
      },
      yAxis: {
        title: {
          text: 'RR %'
        },
        plotLines: [
          {
            value: 70,
            color: '#ff5252',
            dashStyle: 'longdash',
            width: 1,
            label: {
              // text: 'URR target'
            }
          },
          {
            value: 60,
            color: 'rgb(16, 167, 255)',
            dashStyle: 'longdash',
            width: 1,
            label: {
              // text: 'Last month average RR B2M'
            }
          },
          {
            value: 30,
            color: '#00d58d',
            dashStyle: 'longdash',
            width: 1,
            label: {
              // text: 'Last month average RR IS'
            }
          }
        ]
      },
      series: [],
      time: {
        useUTC: false
      },
      plotOptions: {
        area: {
          fillColor: {

          },
          marker: {
            enabled: false
          }
        },
        series: {

        }
      },
      tooltip: {
        xDateFormat: '%d.%m.%Y'
      }
      
    });

  }

  ngOnInit() {

    const urr: Highcharts.SeriesOptions = {
      data: this.dataService.getLongterm(70, 95),
      name: 'URR',
      color: '#ff5252'
    };

    const rrb2m: Highcharts.SeriesOptions = {
      data: this.dataService.getLongterm(45, 65),
      name: 'RR B2M',
      color: 'rgb(12, 199, 255)'
    };

    const rris: Highcharts.SeriesOptions = {
      data: this.dataService.getLongterm(10, 40),
      name: 'RR IS',
      color: '#00d58d'
    };

    this.chart.addSeries(urr);
    this.chart.addSeries(rrb2m);
    this.chart.addSeries(rris);


  }

}
